<template>
  <div>
    <div
      v-ellipsis="isEllipsis"
      data-ellipsis="<span style='color: #30A3D7'>...</span>"
      class="detail"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {},
    ellipsis: {
      default: true,
    },
  },
  name: 'contentEclipse',
  data() {
    return {};
  },
  computed: {
    isEllipsis() {
      return this.ellipsis ? 3 : 0; //rows 0: show all text
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transform-origin: top;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

span {
  margin: 0;
}

.detail {
  font-size: 15px;
  color: #4a4a4a;
  letter-spacing: 0.52px;
  word-wrap: break-word;
}
.text-ellipsis {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-emphasis-color: red;
}
.ellipsis-style {
  width: 100%;
}
</style>
