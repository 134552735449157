<template>
  <div ref="app" class="app">
    <section style="position: relative" v-for="i in list" :key="i.id">
      <div
        class="app-card"
        :class="{ mask: !i.is_have_quota_to_write || i.is_write }"
        v-show="list.length !== 0"
      >
        <section class="card-content">
          <div class="title">{{ i.title }}</div>
          <div class="reward" v-show="i.feedback_point !== 0">
            任務完成好禮：
            <img
              src="../../assets/image/ic-point@3x.png"
              style="width: 12px; height: 12px; margin: 0 5px"
            />
            {{ i.feedback_point }}點雲寶點數
          </div>
          <div class="date">到期日：{{ i.available_end_datetime }}</div>
          <img
            class="image lazy-img"
            v-lazy="i.image"
            v-show="i.image !== ''"
          />
          <content-eclipse
            class="content"
            :content="i.content"
          ></content-eclipse>
        </section>

        <section class="btn" @click="goToQuestionnaire(i)">填寫問卷 ></section>
      </div>
      <section
        class="mask-btn"
        v-show="!i.is_have_quota_to_write || i.is_write"
      >
        {{ i.is_write ? '已填寫' : i.is_have_quota_to_write ? '' : '已額滿' }}
      </section>
    </section>

    <div v-show="list.length === 0" class="empty">
      <img
        src="../../assets/image/cloudbaby.png"
        width="203"
        height="149"
        style="margin-bottom: 24px"
      />
      目前尚未有任務～
      <br />
      敬請期待
    </div>
  </div>
</template>
<script>
import { getList, getUserId } from '@/api/targetQ';
import { mapActions, mapGetters } from 'vuex';
import ContentEclipse from '@/components/common/ContentEclipse.vue';

export default {
  name: 'AppList',
  components: { ContentEclipse },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getQuesList();
    this.sentApp({
      action: 'setLogEvent',
      payload: { name: '點擊_問卷列表_填寫問卷' },
    });
    this.$refs.app.scrollIntoView({
      behavior: 'smooth',
    });

    //埋GA EVENT LOG
    let source = this.$router.query?.publishSource;
    if (source) {
      switch (source) {
        case 'invoiceDetail':
          this.sentApp({
            action: 'setLogEvent',
            payload: { name: '點擊_問卷banner入口_發票明細' },
          });
          break;
        case 'review':
          this.sentApp({
            action: 'setLogEvent',
            payload: { name: '點擊_問卷banner入口_探索雲端' },
          });
          break;
      }
    }
  },
  activated() {
    this.getQuesList();
    this.$refs.app.scrollIntoView({
      behavior: 'smooth',
    });
  },
  computed: {
    ...mapGetters(['getAppUserToken']),
  },
  methods: {
    ...mapActions(['setAppToken', 'setCurrentQuestionnaire', 'sentApp']),
    async getQuesList() {
      await getUserId({ id_token: this.$route.query.access_token }).then(
        (res) => {
          this.setAppToken({
            ...{ hash_user_id: res.hash_user_id, enter_page: 'app-list' },
          });
        }
      );

      const { data } = await getList({
        hash_user_id: this.getAppUserToken.hash_user_id,
      });
      this.list = data;
    },
    goToQuestionnaire(question) {
      if (question.is_have_quota_to_write && !question.is_write) {
        this.setCurrentQuestionnaire(question);
        this.$router.push({
          path: '/app-intro',
          query: {
            id: question.id,
            access_token: this.$route.query.access_token,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app-card {
  background-color: #fff;
  position: relative;
  margin-bottom: 20px;

  &:before {
    content: '';
    background-color: #ff9f2e;
    border-radius: 999px;
    position: absolute;
    top: 5px;
    left: 6px;
    width: 8px;
    height: 8px;
  }

  .card-content {
    padding: 14px 18px 8px 18px;
    font-size: 13px;
    border-right: solid 1.2px #ebebeb;
    border-left: solid 1.2px #ebebeb;
    border-top: solid 1.2px #ebebeb;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .title {
      color: #4a4a4a;
      font-family: PingFangTC-Medium;
      font-size: 15px;
      font-weight: 500;
    }

    .reward {
      border-radius: 3px;
      line-height: 1.54;
      letter-spacing: 0.45px;
      color: #30a3d7;
      margin-top: 5px;
      display: flex;
      align-items: center;
    }

    .date {
      color: #979797;
      margin-top: 6px;
    }

    .image {
      border-radius: 5px;
      margin-top: 8px;
      width: 100%;
    }

    .content {
      color: #4a4a4a;
      margin: 10px 0 0 0;
    }
  }

  .btn {
    color: #fff;
    background-color: #30a3d7;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 10px;
  }

  &.mask {
    background: rgba(0, 0, 0, 0.6);
    filter: brightness(0.5);
    position: relative;
    z-index: 2;
    border-radius: 5px;
  }
}

.mask-btn {
  border-radius: 4px;
  border: solid 2px #fff;
  padding: 6px 23px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.empty {
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #4a4a4a;
}
</style>
